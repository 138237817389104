:root {
  --primary: #7b86b8;
  --dark: #424874;
  --secondary: #dcd6f7;
  --background: #f9f8ff;
  --text: #626262;
  --border: #c9c9c9;
  --light: #fff;
  --orrange: #ff6347;
  --blue: #4267b2;
  --roundness: .5rem;
}
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: var(--text);
  text-decoration: none;
}
body{
  position: relative;
}



/* <-------- header start ---------------------> */




header{
  background: var(--background);
  position: sticky;
  top: 0;
  /* width: 100vw; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  border-bottom: rgba(184, 184, 184, 0.4) 1px solid;  
  z-index: 3; 
  min-height: 7vh;
  /* box-shadow: 1px 1px 10px black; */
  
} 
header div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img{
  height: 3rem;
  width: 6rem;
  cursor: pointer;
}
header img , #left_side-bar .manu img{
  height: 25px;
  width: 25px;
  cursor: pointer;
  
}
.search{
  position: relative;
  width: 90%;
  display: flex;
  align-items: center;
}
header .search{
  width: 50%;
  height: 50px;

}
header .search input{
  width:100%;
  height: 70%;
  padding: 0 3%;
  font-size: 1rem;
}
.search input{
  width: 100%;
  border: none;
  border-radius:  var(--roundness);
  /* background: #dcd6f7; */
  outline: none;
  box-shadow: 2px 2px 5px rgba(21, 13, 138, 0.2);
  transition: box-shadow ease-in-out .3s;
}
.search input:hover{
  box-shadow: 5px 5px 10px rgba(21, 13, 138, 0.3);
}
.search:hover > .serch_icon{
  fill: rgb(54, 54, 54);
}
/* .search input::placeholder{
  color:#8c889c73;
} */
.add-f img{
  /* border-radius: 50%; */
  /* box-shadow: 5px 5px 10px rgba(21, 13, 138, 0.2); */
  width: 2rem;
  height: 2rem;
  }
  .add-f img{
      /* box-shadow: 5px 5px 10px rgba(21, 13, 138, 0.3); */
      }
.serch_icon{
  position: absolute;
  right: 8px;
  cursor: pointer;
  fill: var(--text);
  height: .9rem;
  width: .9rem;
  fill: var(--border);
  transition: fill .3s ease-in-out;
  /* margin: 0 .5%; */
} 
.Toggel_menu{
  position: fixed;
  top: 2.5vh;
  left: 20px;
  cursor: pointer;
  height: 12px;
  width: 25px;
  display: none;
  z-index: 5;
}

.Toggel_menu div{
  width: 100%;
  height: 2px;
  background: var(--dark);
  z-index: 100;
  position: absolute;
  transition: .5s ease-in-out all;
}
.Toggel_menu div:nth-last-child(1){
  top: 5px;
}
.Toggel_menu div:nth-last-child(3){
  bottom: 0;
}

/* <-------------------------------------main div container  ------------> */

.main-div{
  /* background-color:blue; */
  /* display: flex; */
  /* justify-content:center; */
  /* align-items: center; */
  
}

/* <<<<<<<<<<<<<<<<<<     laft side bar div  >>>>>>>>>>>d */

#left_side-bar{
  min-height: 92vh;
  overflow-y: auto;
  width: 15%;
  background:var(--background);
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 13px 6px rgba(0,0,0,0.1);
  position: fixed;
  left: 0%;
  top: 7vh;
  z-index: 1;
  align-items: stretch;
  transition: 2s ease-in-out all;
}

#left_side-bar::-webkit-scrollbar{
  width: 0px;
  
}
.left_div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  
}
.user_profile{
  background: var(--light);
  width: 100%;
  height: 30%;
  padding: 1rem 0;
  border-radius:  var(--roundness);
  margin: .5rem 0;
}
.user_profile img{
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  object-fit: cover;
}
.user_profile h4{
  margin: 10px 0px 0px 0px;
}
.user_profile h5{
  font-size: 0.7rem;
}
.user_profile h5 span{
  margin-right: 5px;
}
.user_profile a{
  text-decoration: none;
  color:black;
  font-size: 0.7rem;
  color: var(--light);
  
}
.user_profile div{
  background: var(--dark);
  margin-top: 10px;
  display: flex;
  padding: 5px;
  border-radius:  var(--roundness);
  align-items: center;
}
.user_profile div img{
  width: 15px;
  height: 15px;
  margin-right: 3px;
}

/* <<<<<<<<<<<<<<<<<< meanu part of laft side bar >>>>>>>>>>>> */

.manu{
  padding: 1rem 0;
}

.hed_titel{
  font-size: .8rem;
  font-weight: 100;
  color:rgb(206, 206, 206);
}
.manu ul{
  width: 100%;
}
.manu ul li{
  list-style: none;
  padding: .7rem .6rem;
  display: flex; 
  align-items: center; 
  border-bottom: 1px solid var(--light);
  width: 100%;
  transition: background .4s ease-in-out;
  /* background: gray; */
  /* border-radius: 20px; */
}
.manu ul li a{
  text-decoration: none;
  width: 100%;
}
.manu ul li a:hover{
  color: black;
}
.manu ul li:hover{
  background: var(--light);
  
}
.manu img{
  margin: 0 .3rem;
}






/* <<<<<<<<<<<<<<<<<<<<<<<<<<<< CONTENT PART >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */






#Content{
  /* background: yellow; */
  width: auto;
  /* min-height: 92vh; */
  margin: 0 25% 0 15%;
  background: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: .2rem 1.5rem;
}

.inner_content{
  border-radius:  var(--roundness);
  margin: .5rem 0;
  padding: 1% 3%;
  background: var(--light);
  width: 100%;
  display: flex;
  flex-direction: column;
  
}
.inner_content h5{
  font-size: .7rem;
  padding-bottom: .4rem;
  border-bottom: 1px solid var(--background);
}
.post img:nth-child(1){
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: .4rem;
}
.post{
  display: flex;
  position: relative;
  padding: .5rem 0;
}
.post input{
  width: 100%;
  border: none;
  outline: none;
  background: var(--background);
  border-radius:  var(--roundness);
  padding:0 1rem;

}
.post_img{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-45%);
  opacity: 70%;
  margin-right: .3rem;
  width: 1.2rem;
    height: 1.2rem;
    right: 1em;
    cursor: pointer;
    fill: #c7c7c7;
}
.post_img:nth-last-child(2){
  right: 3em;
}
.post_img:nth-last-child(3){
  right: 5em;
}
.post_now{
  background: var(--dark);
  padding: .3rem .7rem;
  border-radius:  var(--roundness);
  vertical-align: middle;
  color: white;
  text-decoration: none;
  font-size: .6rem;
  align-self: flex-end;
}



/* <<<<<<<< friend posts >>>>>>>>>> */


.post_had{
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 2% 0;
}
.post_had .friend{
  padding: 0;
}
.post_had .friend img{
  width: 2rem;
  height: 2rem;
}
.post_had .friend div a:nth-child(1) {
  font-size: 0.8rem;
}
.post_had .friend div .follower {
  font-size: 0.6rem;
  color: #a9a9a9;
}
.post_had .dots_inner::before {
  right: -5px;
  top: 0;
}
.post_had .dots_inner::after {
  left: -5px;
  top: 0;
}
.inner_content p{
  font-size: .7rem;
  padding: .4rem;
  word-spacing: 1px;
}
.post_content{
  width: 100%;
  height: 18rem;
  background: var(--background);
}
.post_content img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius:  var(--roundness);
}

.svg_icons{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: .5rem 0;
}
.svg_icons svg{
  width: 1rem;
  height: 1rem;
  padding: 0 .5rem;
  border-radius: var(--roundness);
  box-sizing: content-box;
}

.svg_content{
  display: flex;
  align-items: center;
  padding: .3rem 7%;
  cursor: pointer;
}
.svg_content a{
  font-size: .7rem;
  font-weight: bold;
}
.svg_content a span{
  font-size: .8rem;
  margin-right: .2rem;
}



svg:hover{
  fill: black;
}
.post_img1{
  right: 0;
}










/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< right-bar div >>>>>>>>>>>>>> */


#right_side-bar{
  /* background: green; */
  width: 25%;
  min-height: 92vh;
  background: var(--background);
  position: fixed;
  right: 0;
  top: 7vh;
  z-index: 1;
  box-shadow: 1px 13px 6px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 1rem;
}
.friend{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .4rem;
  border-bottom: 1px solid var(--light);
  padding-bottom: .5rem;
  cursor: pointer;
}
.friend img{
  width: 3rem;
  height: 3rem;
  background: var(--light);
  border-radius: 50%;
  object-fit: cover;
}
.friend div{
  display: flex;
  flex-direction: column;
}
.friend div a{
  text-decoration: none;
}
.friend div a:nth-child(1){
  font-weight: 600;
}
.friend div .follower{
  font-size: .7rem;
}
.had_friend{
  width: 100%;
}

.had_friend .search input{
  padding: .3rem 1rem;
  margin: .5rem 0;
}

.side_dots{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dots_inner{
  width: .2rem;
  height: .2rem;
  background: var(--border);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin-right: 1rem;
}
.dots_inner::after , .dots_inner::before{
  content: "";
  background:var(--border);
  width: .2rem;
  height: .2rem;
  position: absolute;
  cursor: pointer;
}
.dots_inner::before{
  top: -5px;
  
}
.dots_inner::after{
  bottom: -5px;
}
.dots_inner:hover,.dots_inner::after:hover,.dots_inner::before:hover{
  background: black;
}


                      /* midia quarrry */
                      @media screen and (max-width: 900px){
                        #left_side-bar{
                          /* position: fixed; */
                          top: 0;
                          width: 50vw;
                          height: 100vh;
                          /* display: none; */
                          z-index: 3;
                        }
                        .user_profile{
                          margin: 0;
                        }
                        #Content{
                          margin-left: 0;
                        }
                        .Toggel_menu{
                          display: flex;
                        }
                          .togg{
                            display: none;
                          }
                          .togg-done{
                            display: flex;
                          }
                          
                          .toggham{

                          }
                          .toggham div:nth-child(3){
                            display: none;
                          }
                          .toggham div:nth-child(2){
                            transform: rotateZ(45deg) translateX(6px);
                          }
                          .toggham div:nth-child(1){
                            transform: rotateZ(-45deg) translateX(6px);
                          }
                        }
                      @media screen and (max-width: 670px){
                        #Content{
                        margin-right: 0;
                        }
                        #right_side-bar{
                          display: none;
                          width: 50%;
                        }
                      }